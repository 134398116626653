import RasaConversationService from '@/services/conversation'

const state = {
  selected: null,
  preview: null,
}

const mutations = {
  setSelected: (state, payload) => state.selected = payload,
  setPreview: (state, payload) => state.preview = payload,
}

const actions = {
  async send({ rootState, commit }, data) {
    await RasaConversationService.send_message(data).then(res => res.data).catch(err => {
      commit('showSnackbar', `Falha ao enviar a Mensagem!`, {root: true})
    })
  },
  
  async sendInitiative({ rootState, commit, dispatch }, key) {
    let data = {
      phone_number: rootState.rasaConversation.currentConversation.session.sender,
      initiative_key: key,
      service_id: rootState.rasaConversation.currentConversation.session.service_id,
      crm_ref: rootState.rasaConversation.currentConversation.session.customer.crm_ref,
      use_internal_variables: true,
      payload: {
        body: {
          data:{}
        }
      }
    }
    
    await RasaConversationService.send_initiative(data, rootState.rasaServices.currentService.key).then(res => res.data).then(res => {
      if (rootState.rasaConversation.currentConversation.session.status != 'WAITING_TREATMENT') {
        dispatch(
          'rasaSessions/changeSessionStatus', 
          [
            rootState.rasaConversation.currentConversation, 
            'WAITING_CUSTOMER'
          ],
          { root: true })
      }
    }).catch(err => {
      commit('showSnackbar', `Falha ao enviar a Iniciativa, verifique se ela esta ativa!`, {root: true})
    })
  },

  async sendNewInitiative({ rootState, commit, dispatch }, payload) {

    let data = {
      phone_number: payload.phonenumber,
      initiative_key: payload.key,
      crm_ref: payload.crm_ref,
      payload: {
        header: {
          media_url: null,
          file_name: null
        },
        body: {
          data: payload.variables
        }
      }
    }

    await RasaConversationService.send_initiative(data, rootState.rasaServices.currentService.key).then(res => {      
      dispatch(
        'rasaSessions/assignToMe',
        res.data.messages[0].session_id,
        { root: true }
      );

      dispatch(
        'rasaSessions/changeSessionStatus', 
        [
          {session: res.data.messages[0].session},
          'WAITING_CUSTOMER'
        ],
        { root: true });

      commit('showSnackbar', `Iniciativa criada com sucesso!`, {root: true})
    }).catch(err => {
      commit('showSnackbar', `Falha ao enviar a Iniciativa, verifique se ela esta ativa!`, {root: true})
    })
  },

  async sendQuickReply({ rootState, commit }, payload) {
    let data = {
      session_id: rootState.rasaConversation.currentConversation.session.id,
      variables: payload.variables
    }
    await RasaConversationService.send_quick_reply(payload.quick_reply_id, data).then(res => res.data).catch(err => {
      commit('showSnackbar', `Falha ao enviar a Quick Reply, verifique se ela esta ativa!`, {root: true})
    })
  },
}

const getters = {
  selected: (state) => state.selected,
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};